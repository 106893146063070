import { RocketIcon } from "@fluentui/react-icons-mdl2";
import RocketIllustration from './RocketIllustration.inline.svg';
import BlimpIllustration from './BlimpIllustration.inline.svg';

import './pricingCards.scss';

export type PricingCardParams = {
  onPostJob: () => void
}

const PricingCard = ({onPostJob}: PricingCardParams) => {
  return (
    <div className="pricing-cards">
      <div className="pricing-card pricing-card__standard">
        <BlimpIllustration className="pricing-card__blimp" />
        <span className="pricing-card__title pricing-card__titles">Post</span>
        <ul className="pricing-card__checklist">
          <li><RocketIcon /> No Subscription</li>
          <li><RocketIcon /> Auto-Expires After 1 Month</li>
          <li><RocketIcon /> Focus on What Matters</li>
          <li><RocketIcon /> Save Time - Link Full Job Description</li>
        </ul>
        <span className="pricing-card__titles">
          <span className="pricing-card__currency">$</span>
          <span className="pricing-card__cost">150</span>
        </span>
        <button className="pricing-card__button" onClick={onPostJob}>Post Job</button>
      </div>
      <div className="pricing-card pricing-card__promoted">
        <RocketIllustration />
        <span className="pricing-card__title pricing-card__titles">Promoted</span>
        <ul className="pricing-card__checklist">
          <li><RocketIcon /> Everything in Standard</li>
          <li><RocketIcon /> Always on Homepage Through the Month</li>
          <li><RocketIcon /> Default Top of Search List</li>
        </ul>
        <span className="pricing-card__titles">
          <span className="pricing-card__currency">$</span>
          <span className="pricing-card__cost">200</span>
        </span>
        <button className="pricing-card__button" onClick={onPostJob}>Post Job</button>
      </div>
    </div>
  )
};

export default PricingCard;