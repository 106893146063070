import RemoteInclusiveAnimation from './remote_inclusive_bg.mp4';
import DiversityIllustration from './DiversityIllustration.inline.svg';
import DiversityPeopleIllustration from './DiversityPeopleIllustration.inline.svg';
import InclusionIllustration from './InclusionIllustration.inline.svg';

import './searchAnimation.scss';

export enum AnimationSlide {
  house = 0,
  diversity = 1,
  inclusion = 2,
  equity = 3,
  hidden = 4
};

export type SearchAnimationProps = {
  className?: string,
  curAnimation: AnimationSlide
}

const ANIMATION_CLASSES = [
  'search-animation__video-house',
  'search-animation__video-diversity',
  'search-animation__video-inclusion',
  'search-animation__video-equity',
  'search-animation__video-hidden'
]

const SearchAnimation = ({className, curAnimation = 4}: SearchAnimationProps) => {
  let animationClass = ANIMATION_CLASSES[curAnimation];
  return (
    <div className={`search-animation ${className}`}>
      <div  className={`search-animation__slideshow ${animationClass}`}>
        <video autoPlay={true} muted={true} loop={true} id="searchAnimation" className="search-animation__video">
          <source src={RemoteInclusiveAnimation} type="video/mp4" />
        </video>
        <DiversityIllustration className="search-animation__image" />
        <DiversityPeopleIllustration className="search-animation__image" />
        <InclusionIllustration className="search-animation__image" />
      </div>
    </div>
  );
}

export default SearchAnimation;
