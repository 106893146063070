import PricingCards from './cards/pricingCards';
import SearchAnimation, {AnimationSlide} from "./Search/searchAnimation";
import useScroll from "../utils/scrollHook";
import { useRef, useState, useEffect } from 'react';
import SvgLogos, { SvgLogoType } from './animated/SvgLogos';

import './homeScroll.scss';

export type HomeScrollParams = {
  onPostJob: () => void
};

const HomeScroll = ({onPostJob}: HomeScrollParams) => {
  const {scrollY} = useScroll();
  const scrollPanels = useRef([]);
  const [scrollPanelsScrolled, setScrollPanelsScrolled] = useState([false,false,false,false,false]);
  const [animationSlide, setAnimationSlide] = useState(4);
  useEffect(() => {
    scrollPanels.current.forEach((panel, index) => {
      let panelsScrolled = scrollPanelsScrolled;
      if(panel) {
        const domRect = panel.getBoundingClientRect();
        if (domRect && domRect.bottom < (window.innerHeight - 0) && domRect.top > 0) {
          panelsScrolled[index] = true;
          setAnimationSlide(index);
        } else {
          panelsScrolled[index] = false;
        }
        setScrollPanelsScrolled(panelsScrolled);
      }
    });
    //const boundingRect = headerRef.current.getBoundingClientRect();
    // if (scrollPanelRef.current) {
    //   const domRect = scrollPanelRef.current.getBoundingClientRect();
      
    // }
  }, [scrollY]);

  let bgColorClass = "home-scroll__";
  if(animationSlide === 0) {
    bgColorClass += "blue";
  } else if(animationSlide === 1) {
    bgColorClass += "maroon";
  } else if(animationSlide === 2) {
    bgColorClass += "deep-blue";
  } else if(animationSlide === 3) {
    bgColorClass += "dark-grey";
  }

  return (
    <div className={`home-scroll ${bgColorClass}`}>
      <div className="home-scroll__data">
        <div className="home-scroll__card" ref={el => scrollPanels.current[0] = el}>
          <div className="home-scroll__logo">
            <SvgLogos logoType={SvgLogoType.house} className="home-scroll__logo-bg" showAnimation={scrollPanelsScrolled[0]} />
            <div className="home-scroll__logo-text">
              75%
            </div>
          </div>
          <span className="home-scroll__quote">
            "The debate over long-term remote-work options comes as 82% of employees say that having a remote work option at least part time would improve their mental health, with 75% saying it would make them less likely to leave their current jobs"
             - <a href="https://owllabs.com/state-of-remote-work/2021">
              Article 
            </a>
          </span>
        </div>
        <div className="home-scroll__card" ref={el => scrollPanels.current[1] = el}>
          <div className="home-scroll__logo">
            <SvgLogos logoType={SvgLogoType.heart} className="home-scroll__logo-bg" showAnimation={scrollPanelsScrolled[1]} />
            <div className="home-scroll__logo-text">
              25%
            </div>
          </div>
          <span className="home-scroll__quote">
            "Analysis finds that companies in the top quartile for gender diversity on executive teams were 25 percent more likely to have above-average profitability than companies in the fourth quartile"
             - <a href="https://www.mckinsey.com/featured-insights/diversity-and-inclusion/diversity-wins-how-inclusion-matters">
              Article 
            </a>
          </span>
        </div>
        <div className="home-scroll__card" ref={el => scrollPanels.current[2] = el}>
          <div className="home-scroll__logo">
            <SvgLogos logoType={SvgLogoType.tree} className="home-scroll__logo-bg" showAnimation={scrollPanelsScrolled[2]} />
            <div className="home-scroll__logo-text">
              39%
            </div>
          </div>
          <span className="home-scroll__quote">
            "Recent McKinsey research found that 39 percent of all respondents say they have turned down or decided not to pursue a job because of a perceived lack of inclusion at an organization."
             - <a href="https://www.mckinsey.com/business-functions/people-and-organizational-performance/our-insights/sustaining-and-strengthening-inclusion-in-our-new-remote-environment">
              Article 
            </a>
          </span>
        </div>
        <div className="home-scroll__card" ref={el => scrollPanels.current[3] = el}>
          <div className="home-scroll__logo">
            <SvgLogos logoType={SvgLogoType.flower} className="home-scroll__logo-bg" showAnimation={scrollPanelsScrolled[3]} />
            <div className="home-scroll__logo-text">
              80%
            </div>
          </div>
          <span className="home-scroll__quote">
            "Diversity, equity, and inclusion is going to continue to be a primary focus in 2022 for job seekers. 80% of workers have also said that they want to work for a company that is diverse and that values equity and inclusion. Companies will need to make sure DE&I is embedded in everything that they do, including their values, policies, practices, performance expectations, leadership development, and cultural competence."
             - <a href="https://www.cnbc.com/2021/04/30/diversity-equity-and-inclusion-are-important-to-workers-survey-shows.html">
              Article
            </a>
          </span>
        </div>
        <div className="home-scroll__card home-scroll__pricing" ref={el => scrollPanels.current[4] = el}>
          <PricingCards onPostJob={onPostJob} />
        </div>
      </div>
      <div className="home-scroll__panels">
        <div className="home-scroll__panel">
          <SearchAnimation curAnimation={animationSlide} />
        </div>
      </div>
    </div>
  )
};
export default HomeScroll;