import HeartLogo from './multilayer_heart.inline.svg';
import FlowerLogo from './flower_logo_colorful.inline.svg';
import TreeLogo from './tree_logo_colorful.inline.svg';
import HouseLogo from './house_logo_colorful.inline.svg';

import './SvgLogos.scss';

export enum SvgLogoType {
  heart,
  flower,
  tree,
  house
};

export type SvgLogosProps = {
  className: string,
  logoType: SvgLogoType,
  showAnimation: boolean
};

const SvgLogos = ({className, logoType, showAnimation}: SvgLogosProps) => {
  return (
    <>
      {logoType === SvgLogoType.heart && (
        <HeartLogo className={`svg-logo svg-logo__heart ${showAnimation ? 'svg-logo__heart--animated' : 'svg-logo__heart--hidden'} ${className}`} />
      )}
      {logoType === SvgLogoType.tree && (
        <TreeLogo className={`svg-logo svg-logo__tree ${showAnimation ? 'svg-logo__tree--animated': 'svg-logo__tree--hidden'} ${className}`} />
      )}
      {logoType === SvgLogoType.flower && (
        <FlowerLogo className={`svg-logo svg-logo__flower ${showAnimation ? 'svg-logo__flower--animated' : 'svg-logo__flower--hidden'} ${className}`} />
      )}
      {logoType === SvgLogoType.house && (
        <HouseLogo className={`svg-logo svg-logo__house ${showAnimation ? 'svg-logo__house--animated' : 'svg-logo__house--hidden'} ${className}`} />
      )}
    </>
  )
};

export default SvgLogos;